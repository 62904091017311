import React from 'react'
import { graphql } from 'gatsby'
// import { Link } from 'gatsby'
// import Masonry from 'react-masonry-component'
import Img from 'gatsby-image'
import { MDXRenderer } from "gatsby-plugin-mdx"
import { MDXProvider } from "@mdx-js/react"
import Layout from "../components/layout"
import { MDXSharpImg, MDXSrcImg, SafeFluid } from '../components/images';
import { Safe } from '../components/utils';

const shortcodes = {
   Img,
}

const IndexPage = ({ data }) => {
   const body = data.mdx.body
   const frontmatter = data.mdx.frontmatter

   const imgs = {}
   if (frontmatter.bodyImage) {
      const { childImageSharp: c, publicURL } = Safe(frontmatter.bodyImage);
      const { fluid: f } = Safe(c);
      imgs[`Image1`] = ({ align, width }) =>
      f ? (
         <MDXSharpImg align={align} width={width} fluid={SafeFluid(f)} />
      ) : (
         <MDXSrcImg align={align} width={width} src={publicURL || ''} />
      );
   }

   return (
//const IndexPage = ({ data }) => (
   <Layout>
      <article className="sheet">
         <div className="sheet__inner">
            <div className="sheet__body">
               {/* <div className="sheet__image-container">
                  <Img
                  fluid={home.bodyImage.childImageSharp.fluid}
                  style={{
                     maxWidth: home.bodyImage.childImageSharp.presentationWidth,
                     width: home.bodyImage.childImageSharp.presentationWidth,
                     display: "inline-block",
                  }}
                  sizes={home.bodyImage.childImageSharp.sizes}
                  />
               </div> */}
               <div className="sheet__banner">
                  <Img fluid={frontmatter.bodyImage.childImageSharp.fluid} />
               </div>
               <MDXProvider components={shortcodes}>
                  <MDXRenderer imgs={imgs} frontmatter={frontmatter}>{body}</MDXRenderer>
               </MDXProvider>
            </div>
         </div>
      </article>
      {/* <Masonry className="showcase">
      {data.allMdx.edges.map(({ node }) => (
         <div key={node.id} className="showcase__item">
            <figure className="card">
               <Link to={`/${node.fields.slug}`} className="card__image">
               <Img fluid={node.fields.thumbnail.childImageSharp.fluid} />
               </Link>
               <figcaption className="card__caption">
                  <h6 className="card__title">
                     <Link to={`/${node.fields.slug}`}>{node.frontmatter.title}</Link>
                  </h6>
                  <div className="card__description">
                     <p>{node.frontmatter.summary}</p>
                  </div>
               </figcaption>
            </figure>
         </div>
         ))}
      </Masonry> */}
   </Layout>
   )
}

export default IndexPage

export const query = graphql`
   query {
      mdx(fields: { name: { eq: "home" } }) {
         frontmatter {
            title
            bodyImage {
               childImageSharp {
                  fluid(maxWidth: 600, maxHeight: 250, cropFocus: CENTER) {
                     ...GatsbyImageSharpFluid
                     presentationWidth
                  }
               }
            }
         }
         body
      }
   }
`

// fluid(maxWidth: 600) {